export function moneyDisplay(value: number | null): string {
  let valorEmReais: number;

  if (value === null) {
    valorEmReais = 0;
  } else {
    valorEmReais = value / 100;
  }

  const valorFormatado = valorEmReais.toLocaleString('pt-BR', { minimumFractionDigits: 2 });

  return valorFormatado;
}

export function moneyStore(value: string): number {
  const cleanedValueStr = value.replace(/[^0-9.]/g, '');
  const decimalValueStr = cleanedValueStr.replace(',', '.');
  const decimalValue = parseFloat(decimalValueStr) * 100;

  return Math.round(decimalValue);
}

export function textMax(str: string): string {
  if (str.length > 20) {
    return str.slice(0, 20);
  }
  return str;
}