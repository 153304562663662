import { api } from '../utils/Api';
import Add from '../assets/svg/Add';
import Edit from '../assets/svg/Edit';
import { Link } from 'react-router-dom';
import Delete from '../assets/svg/Delete';
import Pagination from '../core/Pagination';
import { moneyDisplay } from '../utils/Helper';
import React, { useEffect, useState } from 'react';

interface Product {
  id: number;
  title: string;
  description: string;
  value: number;
  photo: string;
  category_id: number;
  category: Category;
}

interface Category {
  id: number;
  title: string;
}

interface Link {
  url: string;
  label: string;
  active: boolean;
};

function Products() {
  const [links, setLinks] = useState<Link[]>([]);
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    getProducts('/products');
	}, []);

  const getProducts = (url : string) => {
    api.get(url)
		.then(response => {
      setProducts(response.data.data);
      setLinks(response.data.links);
		})
		.catch(error => {
			console.error('Erro:', error);
		});
  };

  async function handlePagination(link : Link) {
    if(link.url) {
      getProducts(link.url);
    }
  };

  async function deleteProduct(id: number) {
    const data = {
      product_id: id
    };
    api.delete(`/products/delete`, {data})
		.then(response => {
      getProducts('/products');
		})
		.catch(error => {
			console.error('Erro:', error);
		});
  };

  console.log(products);

  return (
    <div className='flex justify-between my-10'>

      <div className="card bg-base-100 w-full shadow-xl">
        <div className="card-body">
          <div className='flex justify-between'>
            <h2 className="card-title">Produtos</h2>
            <div className="card-actions justify-end">
              <Link to="/products/create" className="btn btn-info btn-square btn-sm">
                <Add />
              </Link>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th className='w-[20%]'>Name</th>
                  <th className='w-[20%]'>Categoria</th>
                  <th className='w-[20%]'>Valor</th>
                  <th className='w-[20%] text-right'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {/* row 1 */}
                {
                  products.map(product => (
                    <tr key={product.id} className="hover">
                      <td className='w-[20%]'>{product.title}</td>
                      <td className='w-[20%]'>{product.category.title}</td>
                      <td className='w-[20%]'>R$ {moneyDisplay(product.value)}</td>
                      <th className='w-[20%] text-right'>
                        <Link to={`/products/edit/${product.id}`} className="btn btn-warning btn-sm btn-square ml-3">
                          <Edit />
												</Link>
                        <button className="btn btn-error btn-sm btn-square ml-3" onClick={() => deleteProduct(product.id)}>
                          <Delete />
												</button>
                      </th>
                    </tr>
                  ))
                }

                {/* <tr>
                  <td>
                    <div className="flex items-center gap-3">
                      <div className="avatar">
                        <div className="mask mask-squircle h-12 w-12">
                          <img
                            src="https://img.daisyui.com/images/profile/demo/5@94.webp"
                            alt="Avatar Tailwind CSS Component" />
                        </div>
                      </div>
                      <div>
                        <div className="font-bold">Yancy Tear</div>
                        <div className="text-sm opacity-50">Brazil</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    Wyman-Ledner
                    <br />
                    <span className="badge badge-ghost badge-sm">Community Outreach Specialist</span>
                  </td>
                  <td>Indigo</td>
                  <th>
                    <button className="btn btn-ghost btn-xs">details</button>
                  </th>
                </tr> */}
              </tbody>
            </table>
            <Pagination links={links} handle={handlePagination} />
          </div>
        </div>
      </div>

    </div>
  );
};

export default Products;