import React, { useState } from 'react';
import { moneyDisplay } from '../utils/Helper';
import { PaymentType } from '../utils/Constants';
import Add from '../assets/svg/Add';
import Delete from '../assets/svg/Delete';

interface Payment {
  id: number;
  value: number;
  type: number;
};

interface Props {
  payments?: Payment[];
	storePayment: (payment: Payment) => Promise<void>;
	deletePayment: (payment: Payment) => Promise<void>;
};

function SalesEditPayment(props: Props) {
  const resetPayment = { id: 0, value: 0, type: 0 }; // TODO alterar o campo value para que seja string vazia 
	const [payment, setPayment] = useState<Payment>(resetPayment);

	async function storePayment() {
		// TODO pegar o retorno da props.storePayment e só mudar o setPayment se for sucesso
		props.storePayment(payment);
		setPayment(resetPayment);
	};

	async function deletePayment(payment: Payment) {
		props.deletePayment(payment)
	};

	const total = props.payments?.reduce((sum, payment) => sum + payment.value, 0) || 0;

  return (
		<div className="card bg-base-100 w-[49%] shadow-xl">
			<div className="card-body">
				<h2 className="card-title">Pagamento</h2>

				<div className='flex items-end'>

					<label className="form-control w-full mr-3">
						<div className="label">
							<span className="label-text">Valor</span>
						</div>
						<input type="text" placeholder="Ex.: 100,00" className="input input-bordered w-full" value={payment.value} onChange={ e => setPayment( {...payment, value: Number(e.target.value)} ) } />
					</label>

					<label className="form-control w-full max-w-xs mx-3">
						<div className="label">
							<span className="label-text">Tipo</span>
						</div>
						<select className="select select-bordered" value={payment.type} onChange={ e => setPayment( {...payment, type: Number(e.target.value)} )}>
							<option value={0}>Outros</option>
							<option value={1}>Dinheiro</option>
							<option value={2}>PIX</option>
							<option value={3}>Cartão de Crédito</option>
							<option value={4}>Cartão de Débito</option>
						</select>
					</label>

					<button className="btn btn-info btn-square ml-3" onClick={storePayment}>
            <Add />
					</button>

				</div>

				<div className="overflow-x-auto">
					<table className="table table-zebra">
						<thead>
							<tr>
								<th>Tipo</th>
								<th className='text-center'>Valor</th>
								<th className='text-right'>Ações</th>
							</tr>
						</thead>
						<tbody>
              {
                props.payments &&
                props.payments.map(pyt => (
                  <tr key={pyt.id}>
                    <td>{PaymentType[pyt.type]}</td>
                    <td className='text-center'>R$ {moneyDisplay(pyt.value)}</td>
                    <td className='text-right'>
                      <button className="btn btn-error btn-sm btn-square" onClick={() => deletePayment(pyt)}>
                        <Delete />
                      </button>
                    </td>
                  </tr>
                ))
              }
						</tbody>
					</table>
				</div>

				<div className='flex justify-between mt-5 prose max-w-none'>
					<h3 className='text-accent m-0'>Total:</h3>
					<h3 className='text-accent m-0'>R$ {moneyDisplay(total)}</h3>
				</div>

			</div>
		</div>
  );
};

export default SalesEditPayment;