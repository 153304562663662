import { api } from '../utils/Api';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

interface Category {
  id?: number;
  title?: string;
};

function CategoriesEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [category, setCategory] = useState<Category>();

  useEffect(() => {
		getCategory();
	}, []);

  async function getCategory() {
		api.get(`/categories/${id}`)
		.then(response => {
      setCategory(response.data.data);
		})
		.catch(error => {
			console.error('Erro:', error);
		});
  };

  async function updateCategory() {
    if(category?.title == '') {
      console.log('faz nada porque tá vazio'); //TODO adicionar validação
      return;
    }

		api.patch('/categories/update', category)
		.then(response => {
			console.log('Resposta:', response.data);
      navigate('/categories');
		})
		.catch(error => {
			console.error('Erro:', error);
		});
	};

  return (
    <div className='flex justify-between my-10'>

      <div className="card bg-base-100 w-full shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Editar Categoria</h2>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Nome</span>
            </div>
            <input
            type="text"
            placeholder="Ex.: Bebidas"
            value={category?.title}
            className="input input-bordered w-full" onChange={x => setCategory({...category, title: x.target.value})}/>
          </label>
          <div className="card-actions justify-end">
            <Link to="/categories" className="btn btn-primary">Voltar</Link>
            <button className="btn btn-primary" onClick={updateCategory}>Salvar</button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CategoriesEdit;