import { api } from '../utils/Api';
import { Link } from 'react-router-dom';
import { textMax } from '../utils/Helper';
import React, { useState, useEffect } from 'react';

function Sales() {

	const [sales, setSales] = useState<any[]>([]);

	useEffect(() => {
		getSales();
	}, []);

	async function getSales() {
		api.get('/sales')
		.then(response => {
			setSales(response.data)
		})
		.catch(error => {
			console.error('Erro:', error);
		});
	}

  return (
		<div className='container mx-auto flex flex-col'>
			<div className='mx-10 mt-10 flex justify-end'>
				<Link to="/sales/create" className='btn btn-accent'>Adicionar Mesa (Venda)</Link>
			</div>
			<div className='flex flex-wrap justify-start'>
				{sales.map((sale, index) => (
					<Link
						to={`/sales/edit/${sale.id}`}
						key={index}
						className="w-28 max-w-28 h-28 max-h-28 aspect-square grid place-content-center text-2x bg-primary text-white rounded-box m-10 p-2 break-all"
					>
						{textMax(sale?.title)}
					</Link>
				))}
			</div>
		</div>
  );
};

export default Sales;