import { api } from '../utils/Api';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function SalesCreate() {
  const navigate = useNavigate();
  const [sale, setSale] = useState({
    title: '',
    status: 0
  });

  async function addSale() {
    if(sale.title == '') {
      console.log('faz nada porque tá vazio'); //TODO adicionar validação
      return;
    }

		api.post('/sales/store', sale)
		.then(response => {
			console.log('Resposta:', response.data);
      navigate('/');
		})
		.catch(error => {
			console.error('Erro:', error);
		});
	}

  return (
    <div className='flex justify-between my-10'>

      <div className="card bg-base-100 w-full shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Adicionar Mesa (Venda)</h2>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Nome</span>
            </div>
            <input type="text" placeholder="Ex.: Mesa 1" className="input input-bordered w-full" onChange={x => setSale({...sale, title: x.target.value})}/>
          </label>
          <div className="card-actions justify-end">
            <Link to="/" className="btn btn-primary">Voltar</Link>
            <button className="btn btn-primary" onClick={addSale}>Salvar</button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default SalesCreate;