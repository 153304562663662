import React from 'react';

interface Link {
  url: string;
  label: string;
  active: boolean;
};

interface Props {
	links?: Link[];
  handle: (link: Link) => Promise<void>;
};

const Pagination = ( props: Props ) => {
  return (
    <div className="join w-full flex justify-end mt-5">
      {
        props.links !== undefined ?
        props.links.map((link, index)=> {
          let label = link.label;
          if(label === 'Next &raquo;') {
            label = "Próxima";
          }
          if(label === '&laquo; Previous') {
            label = "Anterior";
          }
          return (
            <button
              className={`join-item btn ${link.active === true ? 'btn-disabled' : ''}`}
              key={index}
              onClick={() => props.handle(link)}>
              {label}
            </button>
          )
        })
        :
        null
      }
    </div>
  );
};

export default Pagination;