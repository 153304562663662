import Add from '../assets/svg/Add';
import Delete from '../assets/svg/Delete';
import Modal from '../core/Modal';
import { api } from '../utils/Api';
import { moneyDisplay } from '../utils/Helper';
import React, { useRef, useState, Fragment, useEffect } from 'react';

interface Product {
  id: number;
  title: string;
  description: string;
  value: number;
  photo: string;
  category_id: number;
  quantity: number;
};

interface Category {
  id: number;
  title: string;
  products: Product[];
}

interface Props {
	products?: Product[];
  quantity: { [key: number]: number };
	storeProduct: (product: Product) => Promise<void>;
	deleteProduct: (product: Product) => Promise<void>;
	setQuantity: React.Dispatch<React.SetStateAction<{ [key: number]: number }>>;
};

function SalesEditProducts(props: Props) {
  const modalRef = useRef<HTMLDialogElement>(null);
  const [products, setProducts] = useState<Product[]>([]);
	const [categories, setCategies] = useState<Category[]>([]);

	useEffect(() => {
		if(props.products) {
			setProducts(props.products);
		}
	}, [props]);

	async function storeProduct(product: Product) {
		props.storeProduct(product);
		if (modalRef.current) {
      modalRef.current.close();
    }
	};

	async function deleteProduct(product: Product) {
		props.deleteProduct(product);
		if (modalRef.current) {
      modalRef.current.close();
    }
	};

  const openModal = () => {
    api.get('/categories/products')
		.then(response => {
      setCategies(response.data.data)
		})
		.catch(error => {
			console.error('Erro:', error);
		});

    if (modalRef.current) {
      modalRef.current.showModal();
    }
  };

	const addQuantity = (product_id: number, value: string) => {
    props.setQuantity({ ...props.quantity, [product_id]: Number(value) });
  };

	const total = props.products?.reduce((sum, product) => sum + product.value, 0) || 0;

  return (
		<>
			<div className="card bg-base-100 w-[49%] shadow-xl">
				<div className="card-body">
          <div className='flex justify-between'>
					  <h2 className="card-title">Produtos</h2>
            <div className="card-actions justify-end">
              <button className="btn btn-info btn-square btn-sm" onClick={openModal}>
                <Add />
              </button>
            </div>
          </div>

					<div className="overflow-x-auto">
						<table className="table table-zebra">
							<thead>
								<tr>
									<th>Nome</th>
									<th className='text-center'>Quantidade</th>
									<th className='text-center'>Valor (UND)</th>
									<th className='text-right'>Ações</th>
								</tr>
							</thead>
							<tbody>
								{
									products.map(product => (
										<tr key={product.id}>
											<td >{product.title}</td>
											<td className='text-center'>{product.quantity}</td>
											<td className='text-center'>R$ {moneyDisplay(product.value)}</td>
											<td className='text-right'>
												<button className="btn btn-error btn-sm btn-square ml-3" onClick={() => deleteProduct(product)}>
                          <Delete />
												</button>
											</td>
										</tr>
									))
								}
							</tbody>
						</table>
					</div>

					<div className='flex justify-between mt-5 prose max-w-none'>
						<h3 className='text-accent m-0'>Total:</h3>
						<h3 className='text-accent m-0'>R$ {moneyDisplay(total)}</h3>
					</div>
				</div>
			</div>

			<Modal ref={modalRef}>
        <div role="tablist" className="tabs tabs-lifted">
          {
            categories.length !== 0 ?
            categories.map((cat, index) => (
              <Fragment key={cat.id}>
                <input type="radio" name="categories" role="tab" className="tab" aria-label={`${cat.title}`} defaultChecked={index === 0}/>
                <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
                  {
                    cat.products.map(product => (
                      <div key={product.id} className='flex justify-between items-center mb-5 bg-accent-content'>
                        <div className='flex items-center w-[50%]'>
                          <img src="https://placehold.co/80x80" alt="teste" className='mx-3'/>
                          <div>
                            <p>{product.title}</p>
                            <p>{product.description}</p>
                            <p>{`R$ ${moneyDisplay(product.value)}`}</p>
                          </div>
                        </div>
                        <input type="number" placeholder='QTD' className="input input-bordered w-[100px]" onChange={val => addQuantity(product.id, val.target.value)} />
                        <button className='btn btn-success mr-3' onClick={() => storeProduct(product)}>Adicionar</button>
                      </div>
                    ))
                  }
                </div>
              </Fragment>
            ))
            :
            <div>Necessário cadastrar categorias e Produtos.</div>
          }
        </div>
      </Modal>
		</>
  );
};

export default SalesEditProducts;