import Sales from '../sales/Sales';
import SalesEdit from '../sales/SalesEdit';
import Products from '../products/Products';
import SalesCreate from '../sales/SalesCreate';
import { Routes, Route } from 'react-router-dom';
import Categories from '../categories/Categories';
import ProductsEdit from '../products/ProductsEdit';
import ProductsCreate from '../products/ProductsCreate';
import CategoriesEdit from '../categories/CategoriesEdit';
import CategoriesCreate from '../categories/CategoriesCreate';

function Content() {
  return (
		<div className='container mx-auto flex flex-col'>

      <Routes>
        <Route path="/" element={<Sales />} />
        <Route path="/sales/create" element={<SalesCreate />} />
        <Route path="/sales/edit/:id" element={<SalesEdit />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/categories/create" element={<CategoriesCreate />} />
        <Route path="/categories/edit/:id" element={<CategoriesEdit />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/create" element={<ProductsCreate />} />
        <Route path="/products/edit/:id" element={<ProductsEdit />} />
        {/* <Route path="/products" element={<Products />} />
        <Route path="/about" element={<About />} /> */}
      </Routes>
		</div>
  );
};

export default Content;