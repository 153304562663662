import { api } from '../utils/Api';
import { useParams } from 'react-router-dom';
import SalesEditPayment from './SalesEditPayment';
import React, { useEffect, useState } from 'react';
import SalesEditProducts from './SalesEditProducts';

interface Sale {
  id: number;
  title: string;
  products: Product[];
  payments: Payment[];
  created_at: string;
};

interface Product {
  id: number;
  title: string;
  description: string;
  value: number;
  photo: string;
  category_id: number;
  quantity: number;
};

interface Payment {
  id: number;
  value: number;
  type: number;
};

function SalesEdit() {
  const { id } = useParams();
  const [sale, setSale] = useState<Sale>();
  const [quantity, setQuantity] = useState<{ [key: number]: number }>({});

  useEffect(() => {
		getSale();
	}, []);

  async function getSale() {
		api.get(`/sales/${id}`)
		.then(response => {
      setSale(response.data.data);
		})
		.catch(error => {
			console.error('Erro:', error);
		});
	};

  // Pagamentos
  async function storePayment(payment: Payment) {
		const data = {
			"sale_id": id,
			"type": payment.type,
			"value": payment.value
		};
		api.post('/payments/store', data)
		.then(response => {
			getSale();
      return {
        status: true,
        message: ''
      };
		})
		.catch(error => {
			console.error('Erro:', error);
      return {
        status: false,
        message: error
      }
		});
	};

  async function deletePayment(payment: Payment) {
		const data = {
			"payment_id": payment.id,
		};
		api.delete('/payments/delete', {data})
		.then(response => {
			getSale();
      return {
        status: true,
        message: ''
      };
		})
		.catch(error => {
			console.error('Erro:', error);
      return {
        status: false,
        message: error
      }
		});
	};

  // Pedidos

  async function storeProduct(product: Product) {
		const data = {
      "sale_id": id,
      "product_id": product.id,
      "quantity": quantity[product.id]
    };

		api.post('/sales/add-product-to-sale', data)
		.then(response => {
			getSale();
		})
		.catch(error => {
			console.error('Erro:', error);
		});
	};

  async function deleteProduct(product: Product) {
		const data = {
      "sale_id": id,
      "product_id": product.id,
    };

		api.delete('/sales/remove-product-from-sale', {data})
		.then(response => {
			getSale();
		})
		.catch(error => {
			console.error('Erro:', error);
		});
	};

  return (
    <div className='flex flex-col my-10'>

      <div className='prose max-w-none'>
        <h2 className='text-center mb-10'>{`${sale?.title} - ${sale?.created_at}`}</h2>
      </div>

      <div className='flex justify-between'>
        <SalesEditProducts products={sale?.products} storeProduct={storeProduct} deleteProduct={deleteProduct} setQuantity={setQuantity} quantity={quantity} />
        <SalesEditPayment payments={sale?.payments} storePayment={storePayment} deletePayment={deletePayment} />
      </div>

      <div className="card bg-base-100 w-full shadow-xl mt-10">
        <div className="card-body">
          <h2 className="card-title">Venda</h2>
          <p>botões para finalizar mesa</p>
        </div>
      </div>

    </div>
  );
};

export default SalesEdit;