import { api } from '../utils/Api';
import { moneyStore } from '../utils/Helper';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface Product {
  title: string;
  description: string;
  value: number;
  photo: string;
  category_id: number;
}

interface Category {
  id: number;
  title: string;
}

function ProductsCreate() {
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    title: '',
    description: '',
    value: 0,
    photo: '',
    category_id: 0
  });
  const [category, setCategory] = useState({
    title: '',
  });
  const [categories, setCategies] = useState<Category[]>([]);

  useEffect(() => {
    getCategories('/categories');
	}, []);

  const getCategories = (url : string) => {
    api.get(url)
		.then(response => {
      setCategies(response.data.data);
		})
		.catch(error => {
			console.error('Erro:', error);
		});
  };

  async function storeProduct() {
    if(product.title == '') {
      console.log('faz nada porque tá vazio'); //TODO adicionar validação
      return;
    }

		api.post('/products/store', product)
		.then(response => {
			console.log('Resposta:', response.data);
      navigate('/products');
		})
		.catch(error => {
			console.error('Erro:', error);
		});
	};

  const parseValueToInt = (value : string) => {
    return moneyStore(value);
  };

  return (
    <div className='flex justify-between my-10'>
      <div className="card bg-base-100 w-full shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Adicionar Produto</h2>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Nome</span>
            </div>
            <input type="text" placeholder="Ex.: Pizza de Calabresa" className="input input-bordered w-full" onChange={x => setProduct({...product, title: x.target.value})}/>
          </label>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Descrição</span>
            </div>
            <input type="text" placeholder="Ex.: Pizza de Calabresa" className="input input-bordered w-full" onChange={x => setProduct({...product, description: x.target.value})}/>
          </label>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Valor</span>
            </div>
            <input type="text" placeholder="Ex.: 44,39" className="input input-bordered w-full" onChange={x => setProduct({...product, value: parseValueToInt(x.target.value)})}/>
          </label>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Categoria</span>
            </div>
            <select className="select select-bordered" onChange={x => setProduct({...product, category_id: parseInt(x.target.value)})}>
              <option value={product.category_id} key={product.category_id} disabled>- Selecionar -</option>
              {
                categories.map(cat => (
                  <option value={cat.id} key={cat.id}>{cat.title}</option>
                ))
              }
            </select>
          </label>
          <div className="card-actions justify-end">
            <Link to="/products" className="btn btn-primary">Voltar</Link>
            <button className="btn btn-primary" onClick={storeProduct}>Salvar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsCreate;