import { api } from '../utils/Api';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function CategoriesCreate() {
  const navigate = useNavigate();
  const [category, setCategory] = useState({
    title: '',
  });

  async function addCategory() {
    if(category.title == '') {
      console.log('faz nada porque tá vazio'); //TODO adicionar validação
      return;
    }

		api.post('/categories/store', category)
		.then(response => {
			console.log('Resposta:', response.data);
      navigate('/categories');
		})
		.catch(error => {
			console.error('Erro:', error);
		});
	};

  return (
    <div className='flex justify-between my-10'>

      <div className="card bg-base-100 w-full shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Adicionar Categoria</h2>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Nome</span>
            </div>
            <input type="text" placeholder="Ex.: Bebidas" className="input input-bordered w-full" onChange={x => setCategory({...category, title: x.target.value})}/>
          </label>
          <div className="card-actions justify-end">
            <Link to="/categories" className="btn btn-primary">Voltar</Link>
            <button className="btn btn-primary" onClick={addCategory}>Salvar</button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CategoriesCreate;