import React from 'react'

interface ModalProps {
  children: React.ReactNode;
}

const Modal = React.forwardRef<HTMLDialogElement, ModalProps>(({ children }, ref) => {
  return (
    <dialog className="modal" ref={ref}>
			<div className="modal-box w-11/12 max-w-5xl">
				{children}
				<div className="modal-action">
					<form method="dialog">
						<button className="btn">Cancelar</button>
					</form>
				</div>
			</div>
		</dialog>
  );
});

export default Modal;